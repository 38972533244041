export const loadingLength = 5;
export const admins: string[] = [
  'gs622@cornell.edu', // Grace
  'tpp38@cornell.edu', // Sophia
  'km653@cornell.edu', // Kevin
  'egk46@cornell.edu', // Ella
  'dj263@cornell.edu', // Daniel
  'ko353@cornell.edu', // Kea-Roy
  'cl2622@cornell.edu', // Casper
  'dm769@cornell.edu', // David
  'jl3587@cornell.edu', // Janet
  'vw92@cornell.edu', // Vicky
];
